<template>
  <div id="featuredetail">
    <MyheadCompnent></MyheadCompnent>
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <FeatureDetailCompnent :featureData='featureData' :head='head' :featureData2='featureData2'
      :chromosomePosition="chromosomePosition" :chromosomePositionDict="chromosomePositionDict"
      :seachParams="seachParams"></FeatureDetailCompnent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import FeatureDetailCompnent from '@/components/Browse/FeatureDetailCompnent.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MetaboliteBrowseCompnentSearch from '@/components/Browse/MetaboliteBrowseCompnentSearch.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  import request from '@/network/request'
  import { ElLoading } from 'element-plus'
  export default {
    name: 'FeatureDetail',
    data () {
      return {
        seachParams: {
          format: 'json',
          searchItem: '',
        },
        featureData: '',
        featureData2: {},
        head: "Requesting !",
        chromosomePosition: [],
        chromosomePositionDict: {},
      }
    },
    methods: {
      getSearchData () {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        request({
          url: 'feature/detail/',
          params: this.seachParams,
        }).then(res => {
          this.featureData = res
          this.head = "Feature Details"
          this.featureData2 = {}
          this.chromosomePosition = res.chromosomePosition
          for (let i = 0; i < res.chromosomePosition.length; i++) {
            this.chromosomePositionDict[String(res.chromosomePosition[i].id)] = res.chromosomePosition[i]
          }
          for (let i = 0; i < this.featureData.LibraryMs.length; i++) {
            this.featureData2[this.featureData.LibraryMs[i].msID] = this.featureData.LibraryMs[i]
          }
          loading.close()
        }).catch(err => {
          console.log(err);
        });
      },
    },
    components: {
      FeatureDetailCompnent,
      MyheadCompnent,
      MyfooterCompnent,
      MetaboliteBrowseCompnentSearch,
    },
    created () {
      this.seachParams.searchItem = this.$route.params.searchItem
      this.getSearchData()
    },
    // 相同路由，参数不一样，路由不跳转，但是beforeRouteUpdate可以监控
    // beforeRouteUpdate (to, from, next) {
    //   this.seachParams.searchItem = to.params.searchItem
    //   this.getSearchData()
    //   next();
    // },
    updated () {
    },
  }
</script>

<style scoped lang="scss">
</style>