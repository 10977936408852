<template>
  <div>
    <el-row>
      <el-col :span="1">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="22">
        <div class="grid-content">
          <div id="featuredetailcompnent" v-if='featureData.FeatureInfo !== undefined'>
            <div class="feature_id">
              <h2>
                Feature ID:
                <a :href="this.$store.state.articleUrlDict[featureData.FeatureInfo[0].species]"
                  style="text-decoration: none;">{{
                  featureData.FeatureInfo[0].featureID }}</a>

              </h2>
              <el-row>
                <el-descriptions class="margin-top" :column="6" :size="medium" border>
                  <el-descriptions-item span="2">
                    <template #label>
                      Species:
                    </template>
                    {{ this.$store.state.speciesDict[featureData.FeatureInfo[0].species] }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2">
                    <template #label>
                      Mode:
                    </template>
                    {{ featureData.FeatureInfo[0].mode }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2">
                    <template #label>
                      Q1 (Parent Ion Mass):
                    </template>
                    {{ featureData.FeatureMs[0].q1 }}
                  </el-descriptions-item>
                  <!-- <el-descriptions-item span="2">
                    <template #label>
                      Ionization Model:
                    </template>
                    {{ featureData.FeatureInfo[0].ionizationModel }}
                  </el-descriptions-item> -->
                  <el-descriptions-item span="2">
                    <template #label>
                      RT:
                    </template>
                    {{ featureData.FeatureInfo[0].rt }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2">
                    <template #label>
                      Identification Level:
                    </template>
                    {{ featureData.FeatureInfo[0].identificationLevel }}
                  </el-descriptions-item>
                  <el-descriptions-item width="20" span="2">
                    <template #label>
                      Metabolite:
                    </template>
                    {{ featureData.FeatureInfo[0].metabolite }}
                  </el-descriptions-item>
                </el-descriptions>
              </el-row>
            </div>
            <div class="feature_plot">
              <h2>
                Feature MS/MS
              </h2>
              <div id='featurePlot'></div>
            </div>
            <div class="feature_anno">
              <h2>
                Feature Annotation <Tips message="<b>'Feature Annotation'</b> includes two parts. 'Article Annotation' is the result of the published research, which includes both standard and putative annotation. 'Library Annotation' is the result by searching the querying MS/MS data of the selected feature in standard MS/MS library of PMhub. For the second part, a modified Dot-product function is used to score the similarity between the experimental spectrum and
                  the standard spectrum in the library.<br><div><img src='/img/math.svg' width='400'></div>"
                  :width="400">

                </Tips>
                <span v-if="!selectedCandidateMetabolite.metabolite" style="color: rgb(230, 50, 26)">(Please select a
                  candidate)</span>
                <span style="color: #6CB273" v-if="selectedCandidateMetabolite.metabolite">(selected:
                  {{selectedCandidateMetabolite.metabolite}})</span>
              </h2>
              <div v-if='featureData.FeatureInfo[0].metabolite != ""'>
                <h3>
                  Article Annotation
                </h3>
                <el-table :data="featureData.FeatureInfo" style="width: 100%" @row-click="changeAlignItem"
                  v-loading="changeAlignItemState">
                  <el-table-column label="PMhub ID" width="180">
                    <template #default="scope">
                      <p>{{ scope.row.metabolite }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Type" width="180">
                    <template #default="scope">
                      <p>{{ scope.row.identificationLevel }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Name" width="300">
                    <template #default="scope">
                      <p>{{ MetaboliteIdDict[scope.row.metabolite]["name"] }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Smiles" width="300">
                    <template #default="scope">
                      <p>{{ MetaboliteIdDict[scope.row.metabolite]["smiles"] }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Structure" width="200">
                    <template #default="scope">
                      <!-- <img :src="getSvg(MetaboliteIdDict[scope.row.metabolite]['svg'])"> -->
                      <!-- {{this.getSvg(MetaboliteIdDict[scope.row.metabolite]['svg'])}} -->
                      <!-- <img :src="MetaboliteIdDict[scope.row.metabolite]['svg']"> -->
                      <!-- <svg :src="MetaboliteIdDict[scope.row.metabolite]['svg']"></svg> -->
                      <!-- <canvas :id="'ArticleAnno_'+MetaboliteIdDict[scope.row.metabolite]['metabolite']"></canvas> -->
                      <div :id="'ArticleAnno_'+MetaboliteIdDict[scope.row.metabolite]['metabolite']"
                        class="structure-draw"></div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="candidateMetabolites.length > 0">
                <h3>
                  Library Annotation
                </h3>
                <el-table :data="candidateMetabolites" style="width: 100%" @row-click="changeAlignItem" max-height="500"
                  v-loading="changeAlignItemState">
                  <el-table-column label="PMhub ID" width="180">
                    <template #default="scope">
                      <p>{{ scope.row.metabolite }}</p>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="msID" label="MS ID" width="220"></el-table-column> -->
                  <el-table-column prop="score" label="Score" width="120"></el-table-column>
                  <el-table-column prop="name" label="Name" width="300"></el-table-column>
                  <el-table-column prop="smiles" label="Smiles" width="300"></el-table-column>
                  <el-table-column label="Structure" width="200">
                    <template #default="scope">
                      <!-- <canvas :id="'LibraryAnno_'+MetaboliteIdDict[scope.row.metabolite]['metabolite']"></canvas> -->
                      <div :id="'LibraryAnno_'+MetaboliteIdDict[scope.row.metabolite]['metabolite']"
                        class="structure-draw"></div>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="Structure" width="200">
                    <template #default="scope">
                      <img :src="getSvg(MetaboliteIdDict[scope.row.metabolite]['svg'])">
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
              <div id='alignPlot' v-show='alignItem'>
                <h3>
                  MS/MS Comparing
                </h3>
              </div>
              <h2 v-show="candidatesTotal.reactionkegg.length > 0">
                Network analysis
              </h2>
              <el-row :gutter="20" v-show="candidatesTotal.reactionkegg.length > 0">

                <el-col :span="16">
                  <h3>Network plot</h3>
                  <div class="block center">
                    <div id="reactions" class="block center" style="border: 1px solid black; height: 400px;">
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div style="height: 65px;"></div>
                  <div v-if="networkSelectedMetabolite.metabolite">
                    <div class="block">
                      <el-descriptions class="margin-top" :column="1" :size="medium" border>
                        <el-descriptions-item span="1" :width="5">
                          <template #label>
                            Metabolite:
                          </template>
                          {{ networkSelectedMetabolite.metabolite }}
                        </el-descriptions-item>
                        <el-descriptions-item span="1" :width="5">
                          <template #label>
                            Name:
                          </template>
                          {{ networkSelectedMetabolite.name }}
                        </el-descriptions-item>
                        <el-descriptions-item span="1">
                          <template #label>
                            Structure:
                          </template>
                          <!-- <img :src="getSvg(networkSelectedMetabolite.svg)" style="width: 150px;"> -->
                          <!-- <canvas id="networkNode"></canvas> -->
                          <div id="networkNode" class="structure-draw"></div>
                        </el-descriptions-item>
                      </el-descriptions>
                    </div>
                  </div>
                  <div v-if="networkSelectedReaction[0]" style="text-align: center">
                    <div style="width: 65px;"></div>
                    <br />
                    <table style="text-align: center">
                      <tr>
                        <th>
                          <!-- <img :src="getSvg(networkIdMetaboliteDict[networkSelectedReaction[0]].svg)"
                            style="width: 150px;vertical-align: middle"> -->
                          <!-- <canvas id="networkNode1"></canvas> -->
                          <div id="networkNode1" class="structure-draw"></div>
                        </th>
                        <th><img src="@/assets/img/arrow.svg"
                            style="object-fit: fill; width:50px;vertical-align: middle">
                        </th>
                        <th>
                          <!-- <img :src="getSvg(networkIdMetaboliteDict[networkSelectedReaction[2]].svg)"
                            style="width: 150px;vertical-align: middle"> -->
                          <!-- <canvas id="networkNode2"></canvas> -->
                          <div id="networkNode2" class="structure-draw"></div>
                        </th>
                      </tr>
                      <tr>
                        <th>{{ networkIdMetaboliteDict[networkSelectedReaction[0]].metabolite }}</th>
                        <th></th>
                        <th>{{
                          networkIdMetaboliteDict[networkSelectedReaction[2]].metabolite }}</th>
                      </tr>
                    </table>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-if="candidatesGeneList.length > 0">
              <h3>Candidate genes<Tips message="<b>Steps</b>: The distance between the gene-regulated chemical reaction and the target metabolite. <br>
                <b>rep</b>: The replications for each metabolic GWAS.<br>
                  <b>-log10(p)</b>: The translated value of p-value of a selected mGWAS (metabolome-based genome-wide association studies) result.<br>
                    <b>KO</b>: The KO ID in KEGG.<br>
                  " :width="400"></Tips>
              </h3>
              <div class="selection_button" style="margin-top: 20px">
                <el-radio-group v-model="reactionSelected">
                  <!-- <el-radio-button label="allreaction">All steps</el-radio-button> -->
                  <el-radio-button label=2>Within 2 steps</el-radio-button>
                  <el-radio-button label=3>Within 3 steps</el-radio-button>
                </el-radio-group>
              </div>
              <el-table :data="candidatesGeneListFilter" style="width: 100%" @row-click="networkGeneSelected"
                max-height="400" v-loading="changeCandidatesState">
                <el-table-column label="gene" width="200" :sortable="true" sort-by="gene">
                  <template #default="scope">
                    <a
                      :href="geneLink[selectSnp.searchItem.species][0]+scope.row.gene+geneLink[selectSnp.searchItem.species][1]">{{
                      scope.row.gene
                      }}</a>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="chromosome" label="chromosome" width="120" sortable></el-table-column> -->

                <el-table-column label="chromosome" width="180" :sortable="true" :sort-method="sortChromosome">
                  <template #default="scope">
                    <p>{{ chromosomePositionDict[scope.row.chromosome].label }}</p>
                  </template>
                </el-table-column>
                <el-table-column label="gene position" width="180" :sortable="true" sort-by="start">
                  <template #default="scope">
                    <p>{{ translateDistance2(scope.row.start,scope.row.end) }}</p>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="start" width="120" :sortable="true" sort-by="start">
                  <template #default="scope">
                    <p>{{ translateDistance(scope.row.start) }}</p>
                  </template>
                </el-table-column>
                <el-table-column label="end" width="120" :sortable="true" sort-by="end">
                  <template #default="scope">
                    <p>{{ translateDistance(scope.row.end) }}</p>
                  </template>
                </el-table-column> -->
                <!-- <el-table-column prop="start" label="start" width="120" sortable></el-table-column>
                <el-table-column prop="end" label="end" width="120" sortable></el-table-column> -->
                <!-- <el-table-column prop="strand" label="strand" width="110" sortable></el-table-column> -->
                <el-table-column prop="snp" label="snp" width="180" sortable></el-table-column>
                <el-table-column label="snp position" width="180" :sortable="true" sort-by="position">
                  <template #default="scope">
                    <p>{{ translateDistance(scope.row.position) }}</p>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="position" label="snp_position" width="150" sortable></el-table-column> -->
                <el-table-column prop="rep" label="rep" width="80" sortable>

                </el-table-column>
                <el-table-column prop="pvalue2" label="-log10(p)" width="140" sortable></el-table-column>
                <!-- <el-table-column label="GO" width="120" :sortable="true" :sort-method="sortGo">
                  <template #default="scope">
                    <p v-for="item in candidatesGeneGoDict[scope.row.gene]">{{ item }}</p>
                  </template>
                </el-table-column> -->
                <el-table-column label="KO" width="120" :sortable="true" :sort-method="sortKo">
                  <template #default="scope">
                    <p v-for="item in candidatesGeneKoDict[scope.row.gene]">{{ item }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="annotation" label="annotation" width="400" sortable></el-table-column>
              </el-table>
            </div>
            <div>
              <h2>
                GWAS Details
              </h2>
              <div>
                <h3 style="display:inline-block">manhaton plot</h3>
                <h3 style="display:inline-block;color:#6CB273" v-show="selectedCandidateMetabolite.metabolite">(for {{
                  selectedCandidateMetabolite.metabolite }})</h3>
                <el-row v-show="reactionSelectedShow">
                  <el-col :span="24">
                    <b>Replications</b>
                    <div>
                      <el-radio v-model="rep" label="rep1">rep1</el-radio>
                      <el-radio v-model="rep" label="rep2">rep2</el-radio>
                      <el-radio v-model="rep" label="rep3">rep3</el-radio>
                    </div>
                    <b>Size of LD interval</b>
                    <Tips
                      message="The phrase 'Size of LD interval' typically refers to the length or size of a linkage
                      disequilibrium (LD) interval. LD intervals are regions of the genome where specific genetic
                      variants or alleles are associated with each other more frequently than would be expected by
                      chance. The size of an LD interval can vary depending on the population, genetic markers, and the
                      specific analysis being conducted. For each selected Manhattan point, a list of the genes within the chosen LD interval around the site is provided below."
                      :width="400"></Tips>
                    <div>
                      <el-select v-model="selectSnp.searchItem.interval" placeholder="Select Interval">
                        <el-option v-for="item in interval" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div style="display: flex;align-items: center">
                      <div id='gwasPlot'></div>
                      <div id='histogramPlot'></div>
                    </div>
                  </el-col>
                  <!-- <el-col :span="8">
                    <el-affix :offset="40">
                      <h3>selected object</h3>
                      <div>
                        <el-row>
                          <el-descriptions class="margin-top" :column="4" :size="medium" border>
                            <el-descriptions-item span="4">
                              <template #label>
                                Selected Metabolites:
                              </template>
                              {{ selectedCandidateMetabolite.metabolite }}
                            </el-descriptions-item>
                            <el-descriptions-item span="2">
                              <template #label>
                                Selected SNP:
                              </template>
                              {{ selectSnp.searchItem.snp }}
                            </el-descriptions-item>
                            <el-descriptions-item span="2">
                              <template #label>
                                chromosome:
                              </template>
                              {{ selectSnp.searchItem.chromosome }}
                            </el-descriptions-item>
                            <el-descriptions-item span="2">
                              <template #label>
                                position:
                              </template>
                              {{ translateDistance(selectSnp.searchItem.position) }}
                            </el-descriptions-item>
                            <el-descriptions-item span="2">
                              <template #label>
                                interval:
                              </template>
                              {{ translateDistance(selectSnp.searchItem.interval) }} bp
                            </el-descriptions-item>
                          </el-descriptions>
                        </el-row>
                      </div>
                    </el-affix>
                  </el-col> -->
                </el-row>
              </div>
              <div v-show="candidates.GeneInfo">
                <h3 style="display:inline-block">genes in the QTL</h3>
                <h3 style="display:inline-block;color:#6CB273" v-show="selectSnp.searchItem.snp">(SNP position: {{
                  translateDistance(selectSnp.searchItem.position) }} bp)</h3>
                <el-table :data="candidates.GeneInfo" style="width: 100%" @row-click="drawTranscriptLink"
                  max-height="600">
                  <el-table-column label="gene" width="280" :sortable="true" sort-by="gene">
                    <template #default="scope">
                      <!-- <a :href="'http://202.194.139.32/cgi-bin/geneDetail.py?search='+scope.row.gene">{{ scope.row.gene
                        }}</a> -->
                      <a
                        :href="geneLink[selectSnp.searchItem.species][0]+scope.row.gene+geneLink[selectSnp.searchItem.species][1]">{{
                        scope.row.gene
                        }}</a>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="gene" label="gene" width="280"></el-table-column> -->
                  <!-- <el-table-column prop="chromosome" label="chromosome" width="180"></el-table-column> -->
                  <el-table-column label="chromosome" width="180" :sortable="true" sort-by="chromosome">
                    <template #default="scope">
                      <p>{{ chromosomePositionDict[scope.row.chromosome].label }}</p>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="start" label="start" width="180"></el-table-column> -->
                  <el-table-column width="180" label="gene position" :sortable="true" sort-by="start">
                    <template #default="scope">
                      <p>{{ translateDistance2(scope.row.start,scope.row.end) }}</p>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="start" width="180" :sortable="true" sort-by="start">
                    <template #default="scope">
                      <p>{{ translateDistance(scope.row.start) }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="end" width="180" :sortable="true" sort-by="end">
                    <template #default="scope">
                      <p>{{ translateDistance(scope.row.end) }}</p>
                    </template>
                  </el-table-column> -->
                  <!-- <el-table-column prop="end" label="end" width="180"></el-table-column> -->
                  <el-table-column width="280" label="distance to selected snp" :sortable="true" sort-by="start">
                    <template #default="scope">
                      <p>{{ getDistance(scope.row.start) }}</p>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="strand" label="strand" width="180" sortable></el-table-column> -->
                  <el-table-column prop="annotation" label="annotation" width="700" sortable></el-table-column>
                </el-table>
              </div>
              <div id='transcriptPlot' v-show="transcriptSelect">
                <h3>
                  transcripts ({{ transcriptSelect }})
                </h3>
              </div>

              <div v-if="candidataGenesResult.length>0">
                <h3>candidate genes in selected LOC</h3>
                <el-table :data="candidataGenesResult" style="width: 100%" @row-click="drawTranscriptLink">
                  <el-table-column label="gene" width="280">
                    <template #default="scope">
                      <!-- <a :href="'http://202.194.139.32/cgi-bin/geneDetail.py?search='+scope.row.gene">{{ scope.row.gene
                        }}</a> -->
                      <a
                        :href="geneLink[selectSnp.searchItem.species][0]+scope.row.gene+geneLink[selectSnp.searchItem.species][1]">{{
                        scope.row.gene
                        }}</a>
                    </template>
                  </el-table-column>
                  <el-table-column label="chromosome" width="180">
                    <template #default="scope">
                      <p>{{ chromosomePositionDict[scope.row.chromosome].label }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="gene position" width="180" :sortable="true" sort-by="start">
                    <template #default="scope">
                      <p>{{ translateDistance2(scope.row.start,scope.row.end) }}</p>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="start" label="start" width="180"></el-table-column>
                  <el-table-column prop="end" label="end" width="180"></el-table-column> -->
                  <el-table-column label="distance to selected snp" width="280">
                    <template #default="scope">
                      <p>{{ getDistance(scope.row.start) }}</p>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="strand" label="strand" width="50"></el-table-column> -->

                  <!-- <el-table-column prop="ko" label="ko" width="100"></el-table-column> -->
                  <el-table-column label="ko" width="180">
                    <template #default="scope">
                      <li v-for="itme in candidataGenes[scope.row.gene][0]">{{ itme }}</li>
                    </template>
                  </el-table-column>
                  <el-table-column prop="annotation" label="annotation" width="700"></el-table-column>
                  <!-- <el-table-column label="pathway" width="180">
                    <template #default="scope">
                      <li v-for="itme in candidataGenes[scope.row.gene][1]">{{ itme }}</li>
                    </template>
                  </el-table-column>
                  <el-table-column label="go" width="180">
                    <template #default="scope">
                      <li v-for="itme in candidataGenes[scope.row.gene][2]">{{ itme }}</li>
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div id="h2id"></div>
  </div>

</template>
<!--  prop="metabolite" -->
<script>
  import request from '@/network/request'
  import common from '@/mymethods/common.js'
  import $ from 'jquery'
  // import ChemDoodle from "@/mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import * as d3 from 'd3'
  import vis from "vis-network/dist/vis-network.min.js"
  import "vis-network/dist/dist/vis-network.min.css"
  import { ref } from 'vue'
  import Tips from '@/components/Common/Tips.vue'
  // 定义全局变量that，与复制为vue中的this
  var that = {}
  export default {
    name: 'FeatureDetailCompnent',
    data () {
      return {
        querydata: {
          page: 1,
          size: 20,
        },
        showif: false,
        candidateMetabolites: [],
        MetaboliteIdDict: {},
        selectedCandidateMetabolite: { metabolite: "", pathway: [], keggpathwayDict: {}, go: [], ko: [], reactionStart: "", kegg: "" },
        alignItem: "",
        alignItemT: "",
        changeAlignItemState: false,
        rep: "",
        manhaton: [],
        selectSnp: {
          format: 'json',
          searchItem: { "species": "", "snp": "", "chromosome": "", "position": "", "interval": 5000000 }
        },
        interval: [{ label: "200K", value: 200000 }, { label: "500K", value: 500000 }, { label: "1M", value: 1000000 }, { label: "2M", value: 2000000 }, { label: "5M", value: 5000000 }],

        candidataGenes: {},
        candidates: "",
        candidatesTotal: { reactionkegg: "" },
        candidatesTotal_KO: {},
        changeCandidatesState: false,
        network: [],
        networkPage: 0,
        networkkeggDict: {},
        networkIdMetaboliteDict: {},
        networkSelectedMetabolite: "",
        networkSelectedReaction: [],
        networkInterval: 500000,
        gwasResult: [],
        featureList: [],
        candidatesGeneList: [],
        reactionSelected: "2",
        reactionSelectedShow: false,
        candidatesGeneListFilter: [],
        candidatesGeneinfo: {},
        // 创建链接
        candidatesGeneKoDict: {},
        candidatesGoGeneDict: {},
        candidatesGeneGoDict: {},
        candidatesKoReactionDict: {},
        candidatesReactionKoDict: {},
        candidatesKoGeneDict: {},
        geneLink: {
          "trae": ["http://202.194.139.32/cgi-bin/geneDetail.py?search=", ""],
          "zema": ["https://www.maizegdb.org/gene_center/gene/", ""],
          "orsa": ["http://rice.uga.edu/cgi-bin/ORF_infopage.cgi?orf=", ""],
          "lyes": ["https://solgenomics.net/locus/", "/view"]
        },
        transcriptPlot: {
          "trae": true,
          "zema": false,
        },
        transcriptSelect: "",
      }
    },
    props: {
      featureData: {
        type: Object,
        default: {},
      },
      featureData2: {
        type: Object,
        default: {},
      },
      head: {
        type: String,
        default: '',
      },
      chromosomePosition: {
        type: Array,
        default: [],
      },
      chromosomePositionDict: {
        type: Object,
        default: {},
      },
      seachParams: {
        type: Object,
        default: {},
      },
    },

    methods: {
      test (row, column, event) {
        common.test(row, column, event)
      },
      pathwayChange (now, prev) {
        this.networkPage = now
      },
      // drawStructure2d () {
      //   let canvas3
      //   let context3
      //   canvas3 = new ChemDoodle.ViewerCanvas('draw2d', 300, 300)
      //   context3 = ChemDoodle.readMOL(this.featureData.FeatureInfo[0].cml)
      //   // context3.scaleToAverageBondLength(50)
      //   canvas3.loadMolecule(context3)
      // },
      // drawStructure3d () {
      //   let canvas3
      //   let context3
      //   canvas3 = new ChemDoodle.TransformCanvas3D('draw3d', 300, 300)
      //   canvas3.styles.set3DRepresentation('Ball and Stick')
      //   canvas3.styles.backgroundColor = 'black';
      //   context3 = ChemDoodle.readMOL(this.featureData.FeatureInfo[0].cml, 1)
      //   canvas3.loadMolecule(context3)
      // },
      candidateMetabolitesGenerate (data) {
        let MetaboliteIdDict = {}
        for (let i = 0; i < data.MetaboliteId.length; i++) {
          MetaboliteIdDict[data.MetaboliteId[i].metabolite] = data.MetaboliteId[i]
        }
        this.MetaboliteIdDict = MetaboliteIdDict
        for (let i = 0; i < data.FeatureAnno.length; i++) {
          let temp = data.FeatureAnno[i].score
          temp = temp.toFixed(2)
          data.FeatureAnno[i].score = temp
          this.candidateMetabolites.push($.extend(data.FeatureAnno[i], MetaboliteIdDict[data.FeatureAnno[i].metabolite]))
        }


        if (this.candidateMetabolites[0] && this.featureData.FeatureInfo[0].metabolite == "") {
          this.alignItem = this.candidateMetabolites[0].msID
          this.alignItemT = this.candidateMetabolites[0].metabolite
        }
        this.$nextTick(() => {
          for (let iNum in this.candidateMetabolites) {
            // common.getStructure(this.candidateMetabolites[iNum].smiles, "LibraryAnno_" + this.candidateMetabolites[iNum].metabolite)
            common.drawMol(this.candidateMetabolites[iNum].mol, "LibraryAnno_" + this.candidateMetabolites[iNum].metabolite)
          }
        })

      },
      drawMsData2 (dataDict) {
        var msDict = []
        for (var key in dataDict) {
          if (key.search("^q") != -1) {
            if (dataDict[key]) {
              msDict.push(dataDict[key])
            }
          }
        }
        msDict[1] = 50
        var msDict2 = []
        for (var i = 0; i < msDict.length; i += 2) {
          msDict2.push({ "ms": msDict[i], "intensity": msDict[i + 1] })
        }
        var xlist = msDict2.map(x => x.ms)
        var ylist = msDict2.map(x => x.intensity)
        // d3.select("#featurePlot").select("svg").remove()
        var svg = d3.select("#featurePlot")
          .append('svg').attr("width", 1000)
          .attr("height", 300)
          .attr('transform', 'translate(100,0)')

        var g = svg.append('g').attr("width", svg.attr("width") * 0.8).attr("height", svg.attr("height") * 0.8).attr('transform', 'translate(30,20)')



        let scaleLinearX = d3.scaleLinear().domain([d3.min(xlist) - 10, d3.max(xlist) + 10]).range([0, g.attr("width")])
        let scaleLinearY = d3.scaleLinear().domain([d3.max(ylist), 0]).range([0, g.attr("height")])
        g.selectAll('text')
          .data(msDict2)
          .enter()
          .append('text')
          .attr('class', function (d) { return "ms_" + String(d.ms).replace('.', '_') })
          .attr('x', function (d, i) {
            return scaleLinearX(d.ms)
          })
          .attr('y', function (d, i) {
            return scaleLinearY(d.intensity);
          })
          .attr('fill', 'rgba(0, 0, 139, 0.8)')
          .text(function (d) {
            return d.ms;
          })

        g.selectAll('rect')
          .data(msDict2)
          .enter()
          .append('rect')
          .attr('x', function (d, i) {
            return scaleLinearX(d.ms)
          })
          .attr('y', function (d, i) {
            return scaleLinearY(d.intensity);
          })
          .attr('width', 3)
          .attr('height', function (d, i) {
            return g.attr("height") - scaleLinearY(d.intensity);
          })
          .attr('fill', 'pink')
          .on("mouseover", function (event, d) {
            d3.select('text.ms_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue")
            d3.select(this)
              .attr("fill", "steelblue");
          })
          // .delay(2000)
          .on("mouseout", function (event, d) {
            d3.select('text.ms_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", "rgba(0, 0, 139, 0.2)")
            d3.select(this)
              .transition()
              .duration(1500)
              .attr("fill", "pink");
          })


        var axisX = d3.axisBottom(scaleLinearX).ticks(5);//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
        var axisY = d3.axisLeft(scaleLinearY).ticks(5)
        g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .attr('transform', 'translate(0,' + g.attr("height") + ')')
          .call(axisX);
        g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .call(axisY);

      },
      drawMsData (dataDict) {
        var ms2 = []
        for (let i in dataDict) {
          ms2.push(dataDict[i])
        }
        ms2 = ms2.splice(4)
        var ms2plot = []
        for (let i in ms2) {
          if (ms2[i]) {
            ms2plot.push(ms2[i])
          }
        }

        common.drawMsData(ms2plot, "#featurePlot", this.featureData.FeatureMs[0].q1, window.screen.width * 0.85)
      },
      drawMsData3 (dataDict) {
        var msDict = []
        for (var key in dataDict) {
          if (key.search("^q") != -1) {
            if (dataDict[key]) {
              msDict.push(dataDict[key])
            }
          }
        }
        msDict[1] = 50
        var msDict2 = []
        for (var i = 0; i < msDict.length; i += 2) {
          msDict2.push({ "ms": msDict[i], "intensity": msDict[i + 1] })
        }
        var xlist = msDict2.map(x => x.ms)
        var ylist = msDict2.map(x => x.intensity)
        // d3.select("#featurePlot").select("svg").remove()
        var svg = d3.select("#featurePlot").append('svg').attr("width", 1000).attr("height", 300).attr('transform', 'translate(100,0)')

        var g_x = 30
        var g_y = 20
        var g = svg
          .append('g')
          .attr("width", svg.attr("width") * 0.8)
          .attr("height", svg.attr("height") * 0.8)
          .attr('transform', 'translate(30,20)')
        var plotWidth = g.attr("width")
        var plotHeight = g.attr("height")
        const zoom = d3.zoom()
          .scaleExtent([1, 32])
          .extent([[0, 0], [plotWidth, plotHeight]])
          .translateExtent([[0, 0], [plotWidth, plotHeight]])
          .on("zoom", zoomed);
        svg.call(zoom)
          .transition()
          .duration(750)
          .call(zoom.scaleTo, 1);
        function zoomed (event) {
          scaleLinearX.range([0, plotWidth].map(d => event.transform.applyX(d)))

          g_text.attr("x", d => scaleLinearX(d.ms));
          g_rect.attr("x", d => scaleLinearX(d.ms));
          axisX = (g1, x) => g1
            .call(d3.axisBottom(x).ticks(Math.floor(event.transform.k * 10)))
          gx.call(axisX, scaleLinearX);
        }



        var scaleLinearX = d3.scaleLinear().domain([0, 1000]).range([0, g.attr("width")])
        var scaleLinearY = d3.scaleLinear().domain([100, 0]).range([0, g.attr("height")])
        var g_text = g.selectAll('text')
          .data(msDict2)
          .enter()
          .append('text')
          .attr('class', function (d) { return "ms_" + String(d.ms).replace('.', '_') })
          .attr('x', function (d, i) {
            return scaleLinearX(d.ms)
          })
          .attr('y', function (d, i) {
            return scaleLinearY(d.intensity);
          })
          .attr('fill', 'rgba(0, 0, 139, 0.8)')
          .text(function (d) {
            return d.ms;
          })

        var g_rect = g.selectAll('rect')
          .data(msDict2)
          .enter()
          .append('rect')
          .attr('x', function (d, i) {
            return scaleLinearX(d.ms)
          })
          .attr('y', function (d, i) {
            return scaleLinearY(d.intensity);
          })
          .attr('width', 3)
          .attr('height', function (d, i) {
            return g.attr("height") - scaleLinearY(d.intensity);
          })
          .attr('fill', '#D95F02')
          .on("mouseover", function (event, d) {
            d3.select('text.ms_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue")
            d3.select(this)
              .attr("fill", "steelblue");
          })
          // .delay(2000)
          .on("mouseout", function (event, d) {
            d3.select('text.ms_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", "rgba(0, 0, 139, 0.8)")
            d3.select(this)
              .transition()
              .duration(1500)
              .attr("fill", "#D95F02");
          })


        var axisX = (g1, x) => g1
          .call(d3.axisBottom(x).ticks(10))//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
        var axisY = (g1, y) => g1
          .call(d3.axisLeft(y).ticks(5))

        const gx = g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .attr('transform', 'translate(0,' + g.attr("height") + ')')
          .call(axisX, scaleLinearX);
        g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .call(axisY, scaleLinearY);

      },
      drawMsAlign (metaboliteItem) {
        var msDict = []
        var msDictQ1 = this.featureData.FeatureMs[0].q1
        var keysT = [...Array(33)].map((v, k) => "q" + k).slice(3)
        for (var key1 of keysT) {
          if (this.featureData.FeatureMs[0][key1]) {
            msDict.push(this.featureData.FeatureMs[0][key1])
            msDict.push(this.featureData.FeatureMs[0][key1 + "I"])
          }
        }
        var MetaboliteIdDict = {}
        for (let i of this.featureData.LibraryMs) {
          MetaboliteIdDict[i.msID] = i
        }
        var msDict2 = []
        for (var key in MetaboliteIdDict[metaboliteItem]) {
          if (key.search("^q") != -1) {
            if (MetaboliteIdDict[metaboliteItem][key]) {
              msDict2.push(MetaboliteIdDict[metaboliteItem][key])
            }
          }
        }


        var msDict2Q1 = msDict2[0]
        msDict2 = msDict2.splice(1)



        common.drawMsAlign(msDict, msDict2, "#alignPlot", msDictQ1, msDict2Q1, window.screen.width * 0.85)
      },
      drawMsAlignT (metaboliteItem) {
        var msDict = []
        for (var key in this.featureData.FeatureMs[0]) {
          if (key.search("^q") != -1) {
            if (this.featureData.FeatureMs[0][key]) {
              msDict.push(this.featureData.FeatureMs[0][key])
            }
          }
        }
        msDict[1] = 50
        let MetaboliteIdDict = {}
        for (let i of this.featureData.LibraryMs) {
          MetaboliteIdDict[i.msID] = i
        }
        var msDict2 = []
        for (var i = 0; i < msDict.length; i += 2) {
          msDict2.push({ "ms": msDict[i], "intensity": msDict[i + 1], "type": "feature" })
        }

        msDict = []
        for (var key in MetaboliteIdDict[metaboliteItem]) {
          if (key.search("^q") != -1) {
            if (MetaboliteIdDict[metaboliteItem][key]) {
              msDict.push(MetaboliteIdDict[metaboliteItem][key])
            }
          }
        }
        msDict.splice(1, 0, 50)
        for (var i = 0; i < msDict.length; i += 2) {
          msDict2.push({ "ms": msDict[i], "intensity": msDict[i + 1], "type": "library" })
        }
        d3.select("#alignPlot").select("svg").remove()
        var svg = d3.select("#alignPlot").append('svg').attr("width", 1000).attr("height", 300).attr('transform', 'translate(100,0)')
        var g = svg.append('g').attr("width", svg.attr("width") * 0.8).attr("height", svg.attr("height") * 0.8).attr('transform', 'translate(30,20)')
        var xlist = msDict2.map(x => x.ms)
        var ylist = msDict2.map(x => x.intensity)
        let scaleLinearX = d3.scaleLinear().domain([d3.min(xlist) - 10, d3.max(xlist) + 10]).range([0, g.attr("width")])
        let scaleLinearY = d3.scaleLinear().domain([d3.max(ylist), -d3.max(ylist)]).range([0, g.attr("height")])
        g.selectAll('text')
          .data(msDict2)
          .enter()
          .append('text')
          .attr('class', function (d) {
            if (d.type == "feature") {
              return "ms_feature_" + String(d.ms).replace('.', '_');
            } else if (d.type == "library") {
              return "ms_library_" + String(d.ms).replace('.', '_');
            };
          })
          .attr('x', function (d, i) {
            return scaleLinearX(d.ms)
          })
          .attr('y', function (d, i) {
            if (d.type == "feature") {
              return scaleLinearY(d.intensity);
            } else if (d.type == "library") {
              return scaleLinearY(-d.intensity - 10);
            }
          })
          .attr('fill', 'rgba(0, 0, 139, 0.8)')
          .text(function (d) {
            return d.ms;
          })

        g.selectAll('rect')
          .data(msDict2)
          .enter()
          .append('rect')
          .attr('x', function (d, i) {
            return scaleLinearX(d.ms)
          })
          .attr('y', function (d, i) {
            if (d.type == "feature") {
              return scaleLinearY(d.intensity);
            } else if (d.type == "library") {
              return scaleLinearY(0);
            };
          })
          .attr('width', 3)
          .attr('height', function (d, i) {
            return (g.attr("height") / 2) - scaleLinearY(d.intensity);
          })
          .attr('fill', '#D95F02')
          .on("mouseover", function (event, d) {
            if (d.type == "feature") {
              d3.select('text.ms_feature_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue");
            } else if (d.type == "library") {
              d3.select('text.ms_library_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue");
            };
            d3.select(this)
              .attr("fill", "steelblue");
          })
          // .delay(2000)
          .on("mouseout", function (event, d) {
            if (d.type == "feature") {
              d3.select('text.ms_feature_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", "rgba(0, 0, 139, 0.8)");
            } else if (d.type == "library") {
              d3.select('text.ms_library_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", "rgba(0, 0, 139, 0.8)");
            };
            d3.select(this)
              .transition()
              .duration(1500)
              .attr("fill", "#D95F02");
          })
        var axisX = d3.axisBottom(scaleLinearX).ticks(5);//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
        var axisY = d3.axisLeft(scaleLinearY).ticks(5)
        g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .attr('transform', 'translate(0,' + g.attr("height") / 2 + ')')
          .call(axisX);
        g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .call(axisY);
      },
      // to filter the candidates within the limited steps to the metabolite that was chosed
      //  to filter the ko in this.candidatesKoGeneDict
      filterCandidatesGeneList (steps) {
        var networkTemp = [...this.candidatesTotal.reactioncmhub, ...this.candidatesTotal.reactionkegg]
        //  to filter the node and reaction in the networkTemp

        var networkTemp2 = []
        var networkMetaboliteT = [this.selectedCandidateMetabolite.reactionStart]
        for (let i = 1; i <= steps; i++) {
          var jMax = networkTemp.length - 1
          var networkMetaboliteT2 = []
          for (let j = jMax; j >= 0; j--) {
            if (networkMetaboliteT.includes(networkTemp[j].nodefrom)) {
              networkMetaboliteT2.push(networkTemp[j].nodeto)
              networkTemp2 = networkTemp2.concat(networkTemp.splice(j, 1))
            } else if (networkMetaboliteT.includes(networkTemp[j].nodeto)) {
              networkMetaboliteT2.push(networkTemp[j].nodefrom)
              networkTemp2 = networkTemp2.concat(networkTemp.splice(j, 1))
            }
          }
          networkMetaboliteT = Array.from(new Set(networkMetaboliteT2))

        }
        //  to filter the ko in this.candidatesReactionKoDict

        var presentElement = document.querySelector("#reactionTemp")
        if (presentElement) {

          presentElement.remove()
        }
        this.plotNetwork(networkTemp2, this.candidatesTotal.networkkegg, "reactions")

        var networkKo = []
        for (let i = 0; i < networkTemp2.length; i++) {
          networkKo = networkKo.concat(this.candidatesReactionKoDict[networkTemp2[i].reaction])
        }
        networkKo = Array.from(new Set(networkKo))

        //  to filter the gene in this.candidatesKoGeneDict
        var networkGene = []
        for (let i = 0; i < networkKo.length; i++) {
          networkGene = networkGene.concat(this.candidatesKoGeneDict[networkKo[i]])
        }
        networkGene = Array.from(new Set(networkGene))

        //  to filter the CandidatesGeneList in this.candidatesGeneList
        this.candidatesGeneListFilter = []
        for (let i = 0; i < this.candidatesGeneList.length; i++) {
          let gene = this.candidatesGeneList[i].gene
          if (networkGene.includes(gene)) {
            this.candidatesGeneListFilter = this.candidatesGeneListFilter.concat(this.candidatesGeneList[i])
          }
        }
        //  to view this.candidatesGeneListFilter replace of this.candidatesGeneList

      },
      plotNetwork (edgedata, networkkegg, networkID = "mynetwork") {
        var presentElement = document.getElementById(networkID)
        var creatElement = document.createElement("div")
        creatElement.id = "reactionTemp"
        creatElement.style.height = "100%"
        creatElement.style.width = "100%"
        creatElement.class = "center"
        presentElement.appendChild(creatElement)
        var colorPlate = ["#8dd3c7", "#bebada", "#fb8072", "#80b1d3", "#fdb462", "#b3de69", "#fccde5", "#d9d9d9", "#bc80bd", "#ccebc5", "#ffed6f", "#ffffb3"]
        var node = new Set()
        var nodeKeggCompound = []
        var edgeList = []
        var networkkeggDict = {}
        for (let i = 0; i < networkkegg.length; i++) {
          networkkeggDict[networkkegg[i].keggId] = networkkegg[i].metabolite
          nodeKeggCompound.push(networkkegg[i].metabolite)
        }
        for (let i = 0; i < edgedata.length; i++) {
          node.add(edgedata[i].nodefrom)
          node.add(edgedata[i].nodeto)
          edgeList.push({
            "from": edgedata[i].nodefrom,
            "to": edgedata[i].nodeto,
            "label": edgedata[i].reaction,
            "group": "Kegg2kegg",
            "color": colorPlate[0]
          })
        }
        var node2 = [...node]
        var nodeList = []
        for (let i = 0; i < node2.length; i++) {
          if (node2[i] == this.alignItemT) {
            nodeList.push({
              "id": node2[i],
              "label": node2[i],
              "group": "kegg compound",
              "metabolite": node2[i],
              color: {
                border: '#bebada',
                background: '#bebada',
                highlight: {
                  border: '#ffed6f',
                  background: '#ffed6f'
                },
              },
            })
          } else if (node2[i] in networkkeggDict) {
            if (networkkeggDict[node2[i]] == this.alignItemT) {
              var nodecolorT = '#bebada'
            } else {
              var nodecolorT = '#fdb462'
            }
            this.networkkeggDict[node2[i]]
            nodeList.push({
              "id": node2[i],
              "label": node2[i],
              "group": "kegg compound",
              "metabolite": networkkeggDict[node2[i]],
              color: {
                border: nodecolorT,
                background: nodecolorT,
                highlight: {
                  border: '#ffed6f',
                  background: '#ffed6f'
                },
              },
            })
          } else {
            nodeList.push({
              "id": node2[i],
              "label": node2[i],
              "group": "kegg compound",
              "metabolite": "",
              color: {
                border: 'grey',
                background: 'grey',
                highlight: {
                  border: '#ffed6f',
                  background: '#ffed6f'
                },
              },
            })
          }
        }
        var options = {
          nodes: {
            borderWidth: 1,
            font: {
              size: 10
            },
          },
          edges: {
            // color: 'lightgray',
            // color: "#8dd3c7",
            arrows: {
              to: {
                enabled: true,
                type: "arrow",
              },
            },
            width: 3
          },
          // physics: true,
          physics: {
            stabilization: false,
            barnesHut: {
              gravitationalConstant: -1000,
              springConstant: 0.01,
              springLength: 50,
              avoidOverlap: 0.0,
              centralGravity: 0.5,
            },
          },
          // physics: {
          //   stabilization: false,
          //   barnesHut: {
          //     gravitationalConstant: -80000,
          //     springConstant: 0.001,
          //     springLength: 200,
          //   },
          // },
          layout: {
            // hierarchical: {
            //   enabled: true,
            // },
            randomSeed: 2,
            improvedLayout: false,
          },
          interaction: {
            tooltipDelay: 200,
            hideEdgesOnDrag: true,
            // hideEdgesOnZoom: true,
          },
        }




        var container = document.getElementById("reactionTemp");
        nodeList = new vis.DataSet(nodeList)
        edgeList = new vis.DataSet(edgeList)
        var data = {
          nodes: nodeList,
          edges: edgeList,
        };

        var network = new vis.Network(container, data, options)
        network.on("doubleClick", this.router2metaboliteDetail)
        network.on("click", this.networkSelected)
        // 
        this.network = [network]

      },
      router2metaboliteDetail (params) {


        var selectedMetabolite = params.nodes[0]
        var searchItemT = ""
        if (selectedMetabolite.includes("C")) {
          if (selectedMetabolite in this.networkkeggDict) {
            searchItemT = this.networkkeggDict[selectedMetabolite]
          }
        } else {
          searchItemT = selectedMetabolite
        }
        if (searchItemT != "") {
          this.$router.push({ name: 'MetaboliteDetail', params: { format: 'json', searchItem: searchItemT } })
        }
      },
      networkSelected (params) {
        // 写公式数据

        if (params.nodes.length > 0) {
          this.networkSelectedMetabolite = this.networkIdMetaboliteDict[params.nodes[0]]
          this.networkSelectedReaction = ""
          this.$nextTick(() => {
            // common.getStructure(this.networkSelectedMetabolite.smiles, "networkNode")
            common.drawMol(this.networkSelectedMetabolite.mol, "networkNode")
          })
        } else {
          let edgeId = params.edges[0]
          let toId = this.network[0].body.edges[edgeId].toId
          let fromId = this.network[0].body.edges[edgeId].fromId
          let label = this.network[0].body.edges[edgeId].options.label
          this.networkSelectedReaction = [fromId, label, toId]
          this.networkSelectedMetabolite = ""
          this.$nextTick(() => {
            // common.getStructure(this.networkIdMetaboliteDict[fromId].smiles, "networkNode1")
            // common.getStructure(this.networkIdMetaboliteDict[toId].smiles, "networkNode2")
            common.drawMol(this.networkIdMetaboliteDict[fromId].mol, "networkNode1")
            common.drawMol(this.networkIdMetaboliteDict[toId].mol, "networkNode2")
          })
        }
      },
      networkGeneSelected (row, column, event) {
        this.changeCandidatesState = true

        let gene = row.gene
        let ko = this.candidatesGeneKoDict[gene]
        let reactions = []
        for (let i in ko) {
          reactions = reactions.concat(this.candidatesKoReactionDict[ko[i]])
        }
        this.networkReactionSelected(reactions)
      },
      networkReactionSelected (reactions) {
        for (let i in this.network) {
          for (let j in this.network[i].body.edges) {
            if (reactions.includes(this.network[i].body.edges[j].edgeType.options.label)) {
              this.network[i].clustering.updateEdge(j, { color: '#DC143C' })
              // this.network[i].body.edges[j].edgeType.options.color["color"] = "#DC143C"






            } else {
              this.network[i].clustering.updateEdge(j, { color: '#8dd3c7' })
              // this.network[i].body.edges[j].edgeType.options.color["color"] = "#8dd3c7"
            }
          }
          this.network[i].redraw()
        }

        this.changeCandidatesState = false
      },
      generateCandidates (genes, gwas, intervel) {
        var candidatesAll = {}
        for (let i in genes) {
          for (let j in gwas) {
            if (genes[i].chromosome == gwas[j].chromosome && Math.abs(genes[i].start - gwas[j].position) < intervel) {
              if (genes[i].gene in candidatesAll) {
                if (gwas[j].pvalue2 > candidatesAll[genes[i].gene][1].pvalue2) {
                  candidatesAll[genes[i].gene] = [genes[i], gwas[j]]
                }
              } else {
                candidatesAll[genes[i].gene] = [genes[i], gwas[j]]
              }
            }
          }
        }
        return candidatesAll
      },
      drawGwas ({ gwasData = "gwasData", chomosomeData = "chomosomeData", selectSnp = "selectSnp", plot_width = 1000, plot_height = 400 } = {}) {


        let pointColor = {}
        for (let i = 0; i < 21; i++) {
          if (i % 3 == 0) {
            // pointColor[String(i + 1)] = "#E16A86"
            // pointColor[String(i + 1)] = "#d2e289"
            pointColor[String(i + 1)] = "#B2966C"
          } else if (i % 3 == 1) {
            // pointColor[String(i + 1)] = "#B88A00"
            // pointColor[String(i + 1)] = "#e4c084"
            pointColor[String(i + 1)] = "#6CB273"
          } else if (i % 3 == 2) {
            // pointColor[String(i + 1)] = "#50A315"
            // pointColor[String(i + 1)] = "#b2cba4"
            pointColor[String(i + 1)] = "#6C88B2"
          }
        }
        d3.select("#gwasPlot").select("svg").remove()
        var svg = d3.select("#gwasPlot")
          .append('svg')
          .attr("width", plot_width)
          .attr("height", plot_height)
          .attr('transform', 'translate(20,0)')
          .attr("viewBox", [0, 0, plot_width, plot_height])
          .attr("style", "max-width: 100%");
        var g = svg.append('g').attr("width", svg.attr("width") * 0.8).attr("height", svg.attr("height") * 0.8).attr('transform', 'translate(80,20)')
        var xlist = chomosomeData.map(x => x.position)
        var ylist = gwasData.map(x => x.pvalue2)
        let scaleLinearX = d3.scaleLinear().domain([0, d3.max(xlist)]).range([0, g.attr("width")])
        let scaleLinearY = d3.scaleLinear().domain([d3.max(ylist), d3.min(ylist)]).range([0, g.attr("height")])

        var ylab = 0.6 * plot_height + 10


        g.selectAll('circle')
          .data(gwasData)
          .enter()
          .append('circle')
          .attr('cx', function (d, i) {
            return scaleLinearX(d.position2)
          })
          .attr('cy', function (d, i) {
            return scaleLinearY(d.pvalue2);
          })
          .attr("r", 5)
          .attr('fill', function (d, i) {
            return pointColor[d.chromosome];
          })
          .style("cursor", "pointer")
          .on("mouseover", function (event, d) {
            d3.select(this)
              .attr("fill", "blue");

            // 
            // 
            // 
            // return tooltip.html("<li>snp: " + d.snp + "</li><li>position: " + d.position + "</li>").attr("transform", "translate(" + event.offsetX + "," + event.offsetY + ")")
            var text = tooltip.selectAll("text")
              .data(["snp: " + d.snp, "position: " + d.position])
              .join("text")
              .attr("x", 0)
              .attr("y", (_, i) => `${i * 1.1}em`)
              .text(dd => dd)
            let xTemp = event.offsetX + 10
            text.attr("transform", "translate(" + xTemp + "," + event.offsetY + ")")
            // return tooltip.style('visibility', 'visible').text("snp: " + d.snp + "; position: " + d.position).attr("transform", "translate(" + event.offsetX + "," + event.offsetY + ")")
          })
          // .delay(2000)
          .on("mouseout", function (event, d) {
            d3.select(this)
              .transition()
              .duration(500)
              .attr("fill", pointColor[d.chromosome]);
            // return tooltip.style('visibility', 'hidden')
          })
          .on("click", function (event, d) {
            selectSnp.searchItem.snp = d.snp;
            selectSnp.searchItem.chromosome = d.chromosome;
            selectSnp.searchItem.position = d.position;

            // 将数据以事件方式发送出来
            that.selectSnpChanged2(selectSnp)
          })
        let tickValues = chomosomeData.map(x => x.position - x.length / 2)
        let tickLabels = chomosomeData.map(x => x.label)
        var axisX = d3.axisBottom(scaleLinearX).tickValues(tickValues).tickFormat((d, i) => tickLabels[i]);//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
        var axisY = d3.axisLeft(scaleLinearY).ticks(5)
        // let tooltip = g.append('text')
        //   .attr('x', 0)
        //   .attr('y', 0)
        //   .style('fill', '#e56b07')
        //   .style('visibility', 'hidden')   // 是否可见（一开始设置为隐藏）
        //   .style('font-size', '18px')
        //   .style('font-weight', 'bold')
        var tooltip = svg.append("g")
          .attr('x', 0)
          .attr('y', 0)
        // .style('visibility', 'hidden')   // 是否可见（一开始设置为隐藏）
        g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .attr('transform', 'translate(0,' + g.attr("height") + ')')
          .call(axisX)
          .style('font-size', '18px')
          .attr('width', 2)
        g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .call(axisY)
          .style('font-size', '18px')
          .attr('width', 2)
        var ylab = svg.append('text')
          .attr('transform', 'translate(20,' + ylab + ') rotate(270)')
        ylab.selectAll('tspan')
          .data(["-log", " 10 ", "p-value"])
          .enter()
          .append('tspan')
          .text(dd => dd)
          .style('font-size', (_, i) => [24, 12, 24][i] + "px")
          .style("font-style", (_, i) => ["normal", "normal", "italic"][i])

        g.selectAll('rect')
          .data(chomosomeData)
          .enter()
          .append('rect')
          .attr('x', function (d, i) {
            return scaleLinearX(d.position)
          })
          .attr('y', 0)
          .attr('width', 1)
          .attr('height', scaleLinearY(d3.min(ylist)))
          .attr('fill', "rgba(0, 0, 139, 0.2)")
      },
      drawTranscriptLink (row, column, event) {
        let gene = row.gene
        this.transcriptSelect = gene

        this.drawTranscript(gene, this.candidates.Transcript, this.featureData.TranscriptColumns)
      },
      drawTranscript (gene, transcript, labels) {
        var transcriptTemp = []
        for (let i = 0; i < transcript.length; i += 1) {
          if (transcript[i].gene == gene) {
            for (let j in transcript[i]) {
              transcriptTemp.push(transcript[i][j])
            }
            var ylist = transcriptTemp.slice(2)
            break
          }
        }
        d3.select("#transcriptPlot").select("svg").remove()
        if (transcriptTemp.length > 0) {
          var svg = d3.select("#transcriptPlot").append('svg')
            .attr("width", window.screen.width * 0.9)
            .attr("height", 600)
            .attr("viewBox", [0, 0, window.screen.width * 0.9, 600])
            .attr('transform', 'translate(50,0)')
            .attr("style", "max-width: 100%");




          var g = svg.append('g').attr("width", svg.attr("width") * 0.8).attr("height", svg.attr("height") * 0.4).attr('transform', 'translate(80,20)')
          var plotWidth = g.attr("width")
          var plotHeight = g.attr("height")
          // const zoom = d3.zoom()
          //   .scaleExtent([1, 32])
          //   .extent([[0, 0], [plotWidth, plotHeight]])
          //   .translateExtent([[0, 0], [plotWidth, plotHeight]])
          //   .on("zoom", zoomed);
          // svg.call(zoom)
          //   .transition()
          //   .duration(750)
          //   .call(zoom.scaleTo, 1);
          // function zoomed (event) {
          //   
          //   scaleLinearX.range([0, plotWidth].map(d => event.transform.applyX(d)))
          //   g_rect.attr("x", d => scaleLinearX(d.label));
          //   axisX = (g1) => g1.call(d3.axisBottom(scaleLinearX).tickSizeOuter(0))
          //   gx.call(axisX);
          // }
          var xlist = [...Array(ylist.length + 1).keys()].slice(1)






          var plotData = []
          for (let i = 0; i < xlist.length; i += 1) {
            plotData.push({ "x": xlist[i], "y": ylist[i], "label": labels[i].xcolumn })
          }

          var scaleLinearX = d3.scaleBand().domain(plotData.map(d => d.label)).range([0, plotWidth]).padding(0.1)

          var scaleLinearY = d3.scaleLinear().domain([d3.max(ylist), 0]).range([0, g.attr("height")])


          var g_rect = g.selectAll('rect')
            .data(plotData)
            .enter()
            .append('rect')
            .attr('x', function (d, i) {
              return scaleLinearX(d.label)
            })
            .attr('y', function (d, i) {
              return scaleLinearY(d.y)
            })
            .attr('width', scaleLinearX.bandwidth())
            .attr('height', function (d, i) {
              return g.attr("height") - scaleLinearY(d.y);
            })
            // .attr('fill', "rgba(0, 0, 139, 0.2)")
            .attr('fill', "#6C6CB2")
          // var axisX = d3.axisBottom(scaleLinearX).tickSizeOuter(0);//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
          var axisX = (g1) => g1.call(d3.axisBottom(scaleLinearX).tickSizeOuter(0))
          var axisY = d3.axisLeft(scaleLinearY).ticks(5)
          var gx = g.append('g')//在原有分g矩形分组里 再加一个x轴分组
            .attr('transform', 'translate(0,' + g.attr("height") + ')')
            .call(axisX)
            .selectAll("text")
            .style("text-anchor", "start")
            .attr("transform", "rotate(45 -10 10)")
            .style('font-size', '16px')
          g.append('g')//在原有分g矩形分组里 再加一个x轴分组
            .call(axisY)
            .style('font-size', '16px');
          var ylab = svg.append('text')
            .attr('transform', 'translate(30,150) rotate(270)')
          ylab.selectAll('tspan')
            .data(["TPM"])
            .enter()
            .append('tspan')
            .text(dd => dd)
            .style('font-size', "20px")
        }
      },
      drawTranscriptTemp (gene, transcript, labels) {
        var transcriptTemp = []
        for (let i = 0; i < transcript.length; i += 1) {
          if (transcript[i].gene == gene) {
            for (let j in transcript[i]) {
              transcriptTemp.push(transcript[i][j])
            }
            var ylist = transcriptTemp.slice(2)
            break
          }
        }
        d3.select("#transcriptPlot").select("svg").remove()
        if (transcriptTemp.length > 0) {
          // var svg = d3.select("#transcriptPlot").append('svg').attr("width", window.screen.width * 0.9).attr("height", 600).attr('transform', 'translate(50,0)').attr("style", "max-width: 100%; height: auto; height: intrinsic;")
          var svg = d3.select("#transcriptPlot").append('svg')
            .attr("width", window.screen.width * 0.9)
            .attr("height", 600)
            // .attr("preserveAspectRatio", "xMidYMid meet")
            .attr("viewBox", [0, 0, window.screen.width * 0.9, 600])
            .attr('transform', 'translate(50,0)')
            .attr("style", "max-width: 100%");
          // .attr("style", "max-width: 100%; height: auto; height: intrinsic;");




          var g = svg.append('g').attr("width", svg.attr("width") * 0.8).attr("height", svg.attr("height") * 0.4).attr('transform', 'translate(80,20)')
          var plotWidth = g.attr("width")
          var plotHeight = g.attr("height")
          // const zoom = d3.zoom()
          //   .scaleExtent([1, 32])
          //   .extent([[0, 0], [plotWidth, plotHeight]])
          //   .translateExtent([[0, 0], [plotWidth, plotHeight]])
          //   .on("zoom", zoomed);
          // svg.call(zoom)
          //   .transition()
          //   .duration(750)
          //   .call(zoom.scaleTo, 1);
          // function zoomed (event) {
          //   scaleLinearX.range([0, plotWidth].map(d => event.transform.applyX(d)))
          //   g_text.attr("x", d => scaleLinearX(d.ms));
          //   g_rect.attr("x", d => scaleLinearX(d.ms));
          //   axisX = (g1, x) => g1
          //     .call(d3.axisBottom(x).ticks(Math.floor(event.transform.k * 10)))
          //   gx.call(axisX, scaleLinearX);
          // }
          var xlist = [...Array(ylist.length + 1).keys()].slice(1)
          var plotData = []
          for (let i = 0; i < xlist.length; i += 1) {
            plotData.push({ "x": xlist[i], "y": ylist[i], "label": labels[i].xcolumn })
          }
          let scaleLinearX = d3.scaleLinear().domain([0, d3.max(xlist)]).range([0, g.attr("width")])
          let scaleLinearY = d3.scaleLinear().domain([d3.max(ylist), 0]).range([0, g.attr("height")])
          var g_rect = g.selectAll('rect')
            .data(plotData)
            .enter()
            .append('rect')
            .attr('x', function (d, i) {
              return scaleLinearX(d.x - 0.25)
            })
            .attr('y', function (d, i) {
              return scaleLinearY(d.y)
            })
            .attr('width', scaleLinearX(d3.max(xlist)) / xlist.length * 0.5)
            .attr('height', function (d, i) {
              return g.attr("height") - scaleLinearY(d.y);
            })
            // .attr('fill', "rgba(0, 0, 139, 0.2)")
            .attr('fill', "#6C6CB2")
          var g_text = g.selectAll('text')
            .data(plotData)
            .enter()
            .append('text')
            .attr('x', function (d, i) {
              return scaleLinearX(d.x)
            })
            .attr('y', function (d, i) {
              return scaleLinearX(d.y)
            })
            .attr('fill', 'rgba(0, 0, 139, 0.2)')
          var axisX = d3.axisBottom(scaleLinearX).tickValues(xlist).tickFormat((d, i) => plotData[i].label);//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
          var axisY = d3.axisLeft(scaleLinearY).ticks(5)
          var gx = g.append('g')//在原有分g矩形分组里 再加一个x轴分组
            .attr('transform', 'translate(0,' + g.attr("height") + ')')
            .call(axisX)
            .selectAll("text")
            .style("text-anchor", "start")
            .attr("transform", "rotate(45 -10 10)")
            .style('font-size', '16px')
          g.append('g')//在原有分g矩形分组里 再加一个x轴分组
            .call(axisY)
            .style('font-size', '16px');
          var ylab = svg.append('text')
            .attr('transform', 'translate(30,150) rotate(270)')
          ylab.selectAll('tspan')
            .data(["TPM"])
            .enter()
            .append('tspan')
            .text(dd => dd)
            .style('font-size', "20px")
        }
      },

      d3draw () {
        // d3.select('rect').attr('fill', 'blue').attr('transform', 'translate(50,50)')
        let g = d3.select('svg').append('g').attr('transform', 'translate(50,50)')
        let g2 = d3.select('svg').append('g').attr('transform', 'translate(150,50)')
        let g3 = d3.select('svg').append('g').attr('transform', 'translate(550,50)')
        let data = [7.3, 5.3, 3.3, 2.3, 1.3]
        let scaleLinear = d3.scaleLinear().domain([0, d3.max(data)]).range([0, 100])
        let rectHeight = 30
        d3.select("#h2id").attr("style", "color: red").html("<h2>from D3.js</h2>")
        d3.select("#h2id").append("p").text("job").attr("style", "color: blue")
        g.selectAll('rect')
          .data(data)
          .enter()
          .append('rect')
          .attr('x', 0)
          .attr('y', function (d, i) {
            return rectHeight * i;
          })
          .attr('width', function (d, i) {
            return scaleLinear(d);//在这里的得到 映射的宽度
          })
          .attr('height', rectHeight - 5)
          .attr('fill', 'pink')
        this.drawMsData(this.featureData.FeatureMs[0])
      },
      selectSnpChanged2 (selectSnp) {

        this.selectSnp = selectSnp

        request({
          url: 'feature/candidates2/',
          params: selectSnp,
        }).then(res => {

          this.candidates = res
        }).catch(err => {
          console.log(err);
        });
      },
      changeAlignItem (row, column, event) {
        this.changeAlignItemState = true;


        this.alignItemT = row.metabolite

        if ("msID" in row) {
          this.alignItem = row.msID
        } else {
          this.alignItem = ""
        }
        this.selectedCandidateMetabolite.metabolite = row.metabolite
        this.selectedCandidateMetabolite.kegg = this.MetaboliteIdDict[this.selectedCandidateMetabolite.metabolite].keggId

        this.changeAlignItemRequest(this.selectedCandidateMetabolite)
      },
      changeAlignItemRequest (selectedCandidateMetabolite) {
        let selectSnp2 = {
          format: 'json',
          searchItem: {
            "species": this.selectSnp.searchItem.species,
            // "pathway": selectedCandidateMetabolite.pathway,
            "metabolite": this.selectedCandidateMetabolite.metabolite,
            // "go": selectedCandidateMetabolite.go,
            // "ko": selectedCandidateMetabolite.ko,
          }
        }

        request({
          url: 'feature/candidates1/',
          params: selectSnp2,
        }).then(res => {
          this.candidatesTotal = res
          this.candidatesTotal_KO = new Set()
          for (let i in this.candidatesTotal.KOReaction) {
            this.candidatesTotal_KO.add(this.candidatesTotal.KOReaction[i]["ko"])
          }

          this.changeAlignItemState = false;
        }).catch(err => {
          console.log(err);
        });
      },
      getKeggcompoundPathway () {
        this.$store.dispatch('getKeggcompoundPathway')
      },
      getPathwayKO () {
        this.$store.dispatch('getPathwayKO')
      },
      getPathwayGO () {
        this.$store.dispatch('getPathwayGO')
      },
      getKOReaction () {
        this.$store.dispatch('getKOReaction')
      },
      getSvg (svgStr) {
        let blob = new Blob([svgStr], {
          type: 'image/svg+xml'
        });
        let blobStr = URL.createObjectURL(blob)
        return blobStr
        // let base64 = window.btoa(svgStr)
        // let template = `<img src="data:image/svg+xml;base64,${base64}">`
        // return template
      },
      getDistance (loc) {
        let temp = String(loc - this.selectSnp.searchItem.position)
        return temp.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      },
      translateDistance (loc) {
        let temp = String(loc)
        return temp.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      },
      translateDistance2 (start, end) {
        let loc = parseInt((start + end) / 2)
        let temp = String(loc)
        return temp.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      },
      sortChromosome (a, b) {


        return parseInt(a.chromosome) - parseInt(b.chromosome)
      },
      sortGo (a, b) {


        if (!this.candidatesGeneGoDict[a.gene]) {
          return -1
        } else if (!this.candidatesGeneGoDict[b.gene]) {
          return 1
        } else {
          return parseInt(this.candidatesGeneGoDict[a.gene][0].replace("GO:", "")) - parseInt(this.candidatesGeneGoDict[b.gene][0].replace("GO:", ""))
        }
      },
      sortKo (a, b) {


        if (!this.candidatesGeneKoDict[a.gene]) {
          return -1
        } else if (!this.candidatesGeneKoDict[b.gene]) {
          return 1
        } else {
          return parseInt(this.candidatesGeneKoDict[a.gene][0].replace("K", "")) - parseInt(this.candidatesGeneKoDict[b.gene][0].replace("K", ""))
        }
      }
    },
    components: {
      Tips
    },
    computed: {
      candidateResult () {
        const { selectedCandidateMetabolite, selectSnp } = this
        return { selectedCandidateMetabolite, selectSnp }
      },
      candidataGenesResult () {
        let result = []
        for (let key in this.candidataGenes) {
          result.push(this.candidatesGeneinfo[key])
        }
        return result
      },
    },
    created () {
      that = this

      if (!this.$store.state.keggcompoundpathway) { this.getKeggcompoundPathway() }
      if (!this.$store.state.pathwayko) { this.getPathwayKO() }
      if (!this.$store.state.pathwaygo) { this.getPathwayGO() }
      if (!this.$store.state.koreaction) { this.getKOReaction() }
      request({
        url: 'feature/detailgwas/',
        params: this.seachParams,
      }).then(res => {

        this.gwasResult = res.Gwas
        this.featureList = res.Feature





        this.reactionSelectedShow = true
        this.rep = "rep1"
        let networkCandidate = this.generateCandidates(this.candidatesTotal.GeneInfo, this.gwasResult, this.networkInterval)


        this.candidatesGeneList = []
        for (let i in networkCandidate) {
          let candidatesGeneListTemp = {}
          for (let j in networkCandidate[i][0]) {
            candidatesGeneListTemp[j] = networkCandidate[i][0][j]
          }
          candidatesGeneListTemp["snp"] = networkCandidate[i][1]["snp"]
          candidatesGeneListTemp["rep"] = networkCandidate[i][1]["rep"]
          candidatesGeneListTemp["position"] = networkCandidate[i][1]["position"]
          candidatesGeneListTemp["pvalue"] = networkCandidate[i][1]["pvalue"]
          candidatesGeneListTemp["pvalue2"] = networkCandidate[i][1]["pvalue2"]
          this.candidatesGeneList.push(candidatesGeneListTemp)
        }
        this.candidatesGeneListFilter = this.candidatesGeneList
        this.candidatesGeneKoDict = common.list2dictList(val.GeneKO, "gene", "ko")
        this.candidatesKoGeneDict = common.list2dictList(val.GeneKO, "ko", "gene")
        this.candidatesGeneGoDict = common.list2dictList(val.GeneGO, "gene", "go")
        this.candidatesGoGeneDict = common.list2dictList(val.GeneGO, "go", "gene")
        this.candidatesReactionKoDict = common.list2dictList(this.$store.state.koreaction, "reaction", "ko")
        this.candidatesKoReactionDict = common.list2dictList(this.$store.state.koreaction, "ko", "reaction")
        this.$nextTick(function () {
          this.filterCandidatesGeneList(Number(this.reactionSelected))
        })
      }).catch(err => {
        console.log(err);
      });


    },
    mounted () {
      // 可以再浏览器上用vue.variable进行调试
      window.vue = this
    },
    updated () {
      // this.drawStructure2d()
      // this.drawStructure3d()
      // this.d3draw()


      this.drawMsData(this.featureData.FeatureMs[0])
      this.candidateMetabolitesGenerate(this.featureData)
      this.$nextTick(() => {
        for (let iNum in this.featureData.FeatureInfo) {



          // common.getStructure(this.MetaboliteIdDict[this.featureData.FeatureInfo[iNum].metabolite].smiles, "ArticleAnno_" + this.featureData.FeatureInfo[iNum].metabolite)
          common.drawMol(this.MetaboliteIdDict[this.featureData.FeatureInfo[iNum].metabolite].mol, "ArticleAnno_" + this.featureData.FeatureInfo[iNum].metabolite)
        }
      })
      // this.drawMsAlign("Exp_p_000000000305")
    },
    watch: {
      featureData: {
        handler (val, olVal) {
          this.selectSnp.searchItem.species = val.FeatureInfo[0].species


        }
      },
      alignItem: {
        handler (val, oldVal) {
          this.drawMsAlign(val)
        }
      },
      rep: {
        handler (val, oldVal) {
          this.manhaton = []
          for (var i = 0; i < this.gwasResult.length; i++) {
            if (this.gwasResult[i].rep == this.rep) {
              this.manhaton.push(this.gwasResult[i])
            }
          }
          var featureListTemp = []
          for (var i = 0; i < this.featureList.length; i++) {
            if (this.featureList[i].rep == this.rep && this.featureList[i].relativeContent > 0) {
              featureListTemp.push(this.featureList[i])
            }
          }
          this.drawGwas({ gwasData: this.manhaton, chomosomeData: this.chromosomePosition, selectSnp: this.selectSnp, plot_width: 1600, plot_height: 400 })
          common.drawFrequencyHistogram(featureListTemp, "relativeContent", "#histogramPlot")
        }
      },
      candidates: {
        handler (val, oldVal) {





          this.candidatesGeneinfo = {}
          for (let i = 0; i < val.GeneInfo.length; i++) {
            this.candidatesGeneinfo[val.GeneInfo[i].gene] = val.GeneInfo[i]
          }
          if (this.candidatesTotal_KO.size > 0) {
            this.candidataGenes = Object()

            for (let i in val.GeneKO) {
              if (this.candidatesTotal_KO.has(val.GeneKO[i].ko)) {
                this.candidataGenes[val.GeneKO[i].gene] = [[val.GeneKO[i].ko]]
              }
            }
          }
        }
      },
      candidatesTotal_KO: {
        handler (val, oldVal) {




          this.candidataGenes = Object()
          for (let i in this.candidates.GeneKO) {
            if (val.has(this.candidates.GeneKO[i].ko)) {
              this.candidataGenes[this.candidates.GeneKO[i].gene] = [[this.candidates.GeneKO[i].ko]]
            }
          }
        }
      },
      // 监控网络数据
      candidatesTotal: {
        handler (val, oldVal) {


          this.networkkeggDict = {}
          for (let i = 0; i < this.candidatesTotal.networkkegg.length; i++) {
            this.networkkeggDict[this.candidatesTotal.networkkegg[i].keggId] = this.candidatesTotal.networkkegg[i].metabolite
            this.networkIdMetaboliteDict[this.candidatesTotal.networkkegg[i].keggId] = this.candidatesTotal.networkkegg[i]
            if (this.candidatesTotal.networkkegg[i].metabolite == this.alignItemT) {
              this.networkIdMetaboliteDict[this.candidatesTotal.networkkegg[i].metabolite] = this.candidatesTotal.networkkegg[i]
            }
          }

          let id = "reactions"
          let edgedata = [...this.candidatesTotal.reactioncmhub, ...this.candidatesTotal.reactionkegg]
          if (this.candidatesTotal.reactioncmhub.length > 0) {
            this.selectedCandidateMetabolite.reactionStart = this.selectedCandidateMetabolite.metabolite
          } else {
            this.selectedCandidateMetabolite.reactionStart = this.selectedCandidateMetabolite.kegg
          }
          let networkkegg = this.candidatesTotal.networkkegg
          // this.plotNetwork(edgedata, networkkegg, id)
          // 用step代替
          // this.reactionSelected = "2"
          let networkCandidate = this.generateCandidates(this.candidatesTotal.GeneInfo, this.gwasResult, this.networkInterval)


          this.candidatesGeneList = []
          for (let i in networkCandidate) {
            let candidatesGeneListTemp = {}
            for (let j in networkCandidate[i][0]) {
              candidatesGeneListTemp[j] = networkCandidate[i][0][j]
            }
            candidatesGeneListTemp["snp"] = networkCandidate[i][1]["snp"]
            candidatesGeneListTemp["rep"] = networkCandidate[i][1]["rep"]
            candidatesGeneListTemp["position"] = networkCandidate[i][1]["position"]
            candidatesGeneListTemp["pvalue"] = networkCandidate[i][1]["pvalue"]
            candidatesGeneListTemp["pvalue2"] = networkCandidate[i][1]["pvalue2"]
            this.candidatesGeneList.push(candidatesGeneListTemp)
          }
          this.candidatesGeneListFilter = this.candidatesGeneList
          this.candidatesGeneKoDict = common.list2dictList(val.GeneKO, "gene", "ko")
          this.candidatesKoGeneDict = common.list2dictList(val.GeneKO, "ko", "gene")
          this.candidatesGeneGoDict = common.list2dictList(val.GeneGO, "gene", "go")
          this.candidatesGoGeneDict = common.list2dictList(val.GeneGO, "go", "gene")
          this.candidatesReactionKoDict = common.list2dictList(this.$store.state.koreaction, "reaction", "ko")
          this.candidatesKoReactionDict = common.list2dictList(this.$store.state.koreaction, "ko", "reaction")
          this.$nextTick(function () {
            this.filterCandidatesGeneList(Number(this.reactionSelected))
          })
        }
      },
      reactionSelected: {
        handler (val, oldVal) {
          this.$nextTick(function () {
            if (val == "allreaction") {
              this.candidatesGeneListFilter = this.candidatesGeneList
            } else {
              this.filterCandidatesGeneList(Number(this.reactionSelected))
            }
          })

        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-descriptions {
    font-size: 18.72px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }



  .el-link {
    color: rgb(77, 9, 236);
    font-size: 18.72px;
  }

  el-text {
    font-size: 18.72px;
  }

  p {
    font-size: 18.72px;
  }

  img {
    width: 100%;
    height: auto;
  }

  .cell {
    font-size: 18.72px;
  }

  .el-table {
    font-size: 18.72px;
  }

  .center {
    text-align: middle;
    vertical-align: middle;
  }

  .structure-draw {
    width: 200px;
    height: 200px;
  }
</style>